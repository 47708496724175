var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_vm._l((_vm.catalog),function(cat,idx){return _c('section',{key:idx,staticClass:"section section-carousel"},[_c('div',{staticClass:"section-header m-container"},[(_vm.$i18n.locale !== 'en')?_c('h2',[_vm._v(" "+_vm._s(cat.title)+" ")]):(cat.translations[_vm.$i18n.locale])?_c('h2',[_vm._v(" "+_vm._s(cat.translations[_vm.$i18n.locale].title)+" ")]):_c('h2',[_vm._v(_vm._s(cat.title))]),(cat.goods.filter(function (good) { return good.volume; }).length > 4)?_c('router-link',{staticClass:"more",attrs:{"to":{
          name: 'ShopCategory',
          params: {
            category: idx
          }
        }}},[_vm._v(_vm._s(_vm.$t("section-menu.see all")))]):_vm._e()],1),_c('div',{staticClass:"carousel-wrapper"},[_c('div',{staticClass:"carousel-container"},[_vm._m(0,true),_vm._l((cat.goods.filter(function (good) { return good.volume; })),function(item,ixG){return [(ixG <= 3)?_c('div',{key:item.product_id,staticClass:"carousel-item shop-item ",class:cat.code === 'alcoholic_beverages' ? 'shop-logo' : ''},[_c('figure',{style:({
                'background-image': ("url(" + (item.image) + ")"),
                cursor:
                  item.description && item.description[this.$i18n.locale]
                    ? 'default'
                    : 'pointer'
              }),on:{"click":function($event){$event.preventDefault();return _vm.showItem(item, idx)}}}),_c('p',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"purchase"},[_c('strong',[(
                    item.price.position && item.price.position === 'start'
                  )?[_vm._v(_vm._s(item.price.currency))]:_vm._e(),_vm._v(" "+_vm._s(item.price.amount)+" "),(item.price.position && item.price.position === 'end')?[_vm._v(_vm._s(item.price.currency))]:[_vm._v("₽")]],2),(!_vm.card.find(function (it) { return it.productId === item.product_id; }))?_c('a',{staticClass:"basket",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                    category: cat.code,
                    productId: item.product_id,
                    action: 'ADD_TO_CARD'
                  })}}}):_c('p',{staticClass:"amount"},[_c('span',{staticClass:"minus",on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                      productId: item.product_id,
                      action: 'DECREMENT_AMOUNT_CARD'
                    })}}}),_c('b',[_vm._v(_vm._s(_vm.card.find(function (it) { return it.productId === item.product_id; }) ? _vm.card.find(function (it) { return it.productId === item.product_id; }).amount : ""))]),_c('span',{staticClass:"plus",class:{
                    disabled:
                      parseInt(item.volume) <=
                      parseInt(
                        _vm.card.find(function (it) { return it.productId === item.product_id; })
                          ? _vm.card.find(function (it) { return it.productId === item.product_id; })
                              .amount
                          : ''
                      )
                  },on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                      productId: item.product_id,
                      action: 'INCREMENT_AMOUNT_CARD'
                    })}}})]),(
                  _vm.card.find(function (it) { return it.productId === item.product_id; }) &&
                    _vm.card.find(function (it) { return it.productId === item.product_id; }).amount
                )?_c('span',{staticClass:"amount-sm",on:{"click":function($event){$event.preventDefault();return _vm.openModal(item)}}},[_vm._v(_vm._s(_vm.card.find(function (it) { return it.productId === item.product_id; }) && _vm.card.find(function (it) { return it.productId === item.product_id; }).amount))]):_vm._e()])]):_vm._e()]}),_vm._m(1,true)],2)])])}),(Object.keys(_vm.modalItem).length)?_c('amount-shop',{attrs:{"item":_vm.modalItem},on:{"close":function($event){_vm.modalItem = {}}}}):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carousel-offset"},[_c('div')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carousel-offset"},[_c('div')])}]

export { render, staticRenderFns }