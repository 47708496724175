<template>
  <div class="modal default-modal amount-modal modal-open">
    <div class="table">
      <div class="table-cell">
        <div class="modal-container align-center">
          <a href="" class="close" @click.prevent="$emit('close')"></a>

          <p>{{ item.title }}</p>

          <div class="modal-content">
            <div class="amount">
              <i
                class="minus"
                :class="{
                  disabled: !card.find(it => it.productId === item.product_id)
                }"
                @click.prevent="
                  changeAmount({
                    productId: item.product_id,
                    action: 'DECREMENT_AMOUNT_CARD'
                  })
                "
              ></i>
              <b>{{
                card.find(it => it.productId === item.product_id)
                  ? card.find(it => it.productId === item.product_id).amount
                  : 0
              }}</b>
              <i
                class="plus"
                :class="{
                  disabled:
                    parseInt(item.volume) <=
                    parseInt(
                      card.find(it => it.productId === item.product_id)
                        ? card.find(it => it.productId === item.product_id)
                            .amount
                        : ''
                    )
                }"
                @click.prevent="
                  changeAmount({
                    productId: item.product_id,
                    action: 'INCREMENT_AMOUNT_CARD'
                  })
                "
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AmountShop",
  props: ["item"],
  computed: {
    ...mapState("shop", {
      catalog: state => state.index,
      card: state => state.card
    }),
    cat() {
      return this.catalog[this.category];
    }
  },
  methods: {
    ...mapActions("shop", {
      actionIndex: "index",
      changeAmount: "changeAmount"
    })
  },
  mounted() {}
};
</script>

<style scoped>
.disabled {
  opacity: 0.4;
}
</style>
