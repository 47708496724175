<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>

    <section
      class="section section-carousel"
      v-for="(cat, idx) in catalog"
      :key="idx"
    >
      <div class="section-header m-container">
        <h2 v-if="$i18n.locale !== 'en'">
          {{ cat.title }}
        </h2>
        <h2 v-else-if="cat.translations[$i18n.locale]">
          {{ cat.translations[$i18n.locale].title }}
        </h2>
        <h2 v-else>{{ cat.title }}</h2>
        <router-link
          v-if="cat.goods.filter(good => good.volume).length > 4"
          class="more"
          :to="{
            name: 'ShopCategory',
            params: {
              category: idx
            }
          }"
          >{{ $t("section-menu.see all") }}</router-link
        >
      </div>

      <div class="carousel-wrapper">
        <div class="carousel-container">
          <div class="carousel-offset"><div></div></div>
          <template
            v-for="(item, ixG) in cat.goods.filter(good => good.volume)"
          >
            <div
              :class="cat.code === 'alcoholic_beverages' ? 'shop-logo' : ''"
              class="carousel-item shop-item "
              :key="item.product_id"
              v-if="ixG <= 3"
            >
              <figure
                @click.prevent="showItem(item, idx)"
                :style="{
                  'background-image': `url(${item.image})`,
                  cursor:
                    item.description && item.description[this.$i18n.locale]
                      ? 'default'
                      : 'pointer'
                }"
              ></figure>
              <!-- <img class="shop-item-alco" src="../../assets/img/18plus.png" alt=""> -->
              <p>{{ item.title }}</p>
              <div class="purchase">
                <strong
                  ><template
                    v-if="
                      item.price.position && item.price.position === 'start'
                    "
                    >{{ item.price.currency }}</template
                  >
                  {{ item.price.amount }}
                  <template
                    v-if="item.price.position && item.price.position === 'end'"
                    >{{ item.price.currency }}</template
                  >
                  <template v-else>₽</template></strong
                >
                <a
                  class="basket"
                  href="#"
                  v-if="!card.find(it => it.productId === item.product_id)"
                  @click.prevent="
                    changeAmount({
                      category: cat.code,
                      productId: item.product_id,
                      action: 'ADD_TO_CARD'
                    })
                  "
                ></a>
                <p class="amount" v-else>
                  <span
                    class="minus"
                    @click.prevent="
                      changeAmount({
                        productId: item.product_id,
                        action: 'DECREMENT_AMOUNT_CARD'
                      })
                    "
                  ></span>
                  <b>{{
                    card.find(it => it.productId === item.product_id)
                      ? card.find(it => it.productId === item.product_id).amount
                      : ""
                  }}</b>
                  <span
                    class="plus"
                    :class="{
                      disabled:
                        parseInt(item.volume) <=
                        parseInt(
                          card.find(it => it.productId === item.product_id)
                            ? card.find(it => it.productId === item.product_id)
                                .amount
                            : ''
                        )
                    }"
                    @click.prevent="
                      changeAmount({
                        productId: item.product_id,
                        action: 'INCREMENT_AMOUNT_CARD'
                      })
                    "
                  ></span>
                </p>
                <span
                  class="amount-sm"
                  @click.prevent="openModal(item)"
                  v-if="
                    card.find(it => it.productId === item.product_id) &&
                      card.find(it => it.productId === item.product_id).amount
                  "
                  >{{
                    card.find(it => it.productId === item.product_id) &&
                      card.find(it => it.productId === item.product_id).amount
                  }}</span
                >
              </div>
            </div>
          </template>
          <div class="carousel-offset"><div></div></div>
        </div>
      </div>
    </section>
    <amount-shop
      :item="modalItem"
      v-if="Object.keys(modalItem).length"
      @close="modalItem = {}"
    ></amount-shop>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AmountShop from "@/components/popup/AmountShop";

export default {
  name: "Shop",
  components: { AmountShop },
  title() {
    return this.$t("pages.shop");
  },
  data: () => {
    return {
      modalItem: {}
    };
  },
  computed: {
    ...mapState("shop", {
      catalog: state => {
        // console.log(state.index);
        return state.index;
      },
      card: state => state.card
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.actionIndex();
    });
  },
  methods: {
    ...mapActions("shop", {
      actionIndex: "index",
      changeAmount: "changeAmount"
    }),
    openModal(item) {
      this.modalItem = item;
    },
    showItem(item, category) {
      // if (item.description && item.description[this.$i18n.locale]) {
      this.$router.push({
        name: "ShopItem",
        params: {
          category: category,
          product: item.product_id
        }
      });
      // } else {
      //   this.changeAmount({ action: "ADD_TO_CARD", productId: item.productId });
      // }
    }
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.4;
}
</style>
